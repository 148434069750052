$(document).ready(function() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    // $('.font-toggle').on('click', function() {
    //     if ($('html').hasClass('large-font')) {
    //         document.cookie = "large-font=no";
    //         $('html').removeClass('large-font');
    //     } else {
    //         document.cookie = "large-font=yes";
    //         $('html').addClass('large-font');
    //     }
    // });

    document.profilestore = new awc.LocalProfileStore();

    $('.profile-control').each(function (i) {
        var storedValue = localStorage.getItem($(this).data('property')) || 0;
        updateProfile($(this), storedValue);
    });

    $('.profile-control').on('click', function (e) {
        var $control = $(this);
        updateProfile($control, (parseInt($control.attr('data-value'), 10) + 1) % $control.data('values').length);
    });

    function updateProfile($control, value) {
        var label = $control.data('values')[value];
        var property = $control.data('property');
        var profileChange = {};

        // store the profile change in localStorage
        localStorage.setItem(property, value);

        // change the profile in the awc profile store
        profileChange[property] = label;
        document.profilestore.changeProfile(profileChange);

        // update the control label and value
        $control.attr('data-value', value);
        // $control.html(label.charAt(0).toUpperCase() + label.substr(1));

        // if this is the context query profile thing, update it
        if (property == 'contrast') {
            if (label == 'increased') {
                document.profilestore.changeProfile({'devicelight': 75});
            } else {
                document.profilestore.changeProfile({'devicelight': 25});
            }
        }
        if (property == 'text-size') {
            if (label == 'increased') {
                document.profilestore.changeProfile({'devicemotion': 75});
            } else {
                document.profilestore.changeProfile({'devicemotion': 25});
            }
        }
    }
});
